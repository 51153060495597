import React from "react"
import Link from "gatsby-link"
import styled from "styled-components"
import Page from "../templates/page"
import PostFeed from "../components/PostFeed/"
import Metadata from "../components/shared/Metadata"
import { authorInfo } from "../utilities/queries"

const gold = "rgba(255, 215, 0, 1)"

const FeaturedPosts = styled("section")`
  background-color: rgba(255, 215, 0, 0.25);
  border: 1px solid ${gold};
  padding: 0.5em 1.25em;
  .card {
    border-color: ${gold};
  }
`

const frontmatter = {
  title: "Writing | Geoff Davis",
  og_url: "/writing",
  twitter_card: "summary",
  twitter_creator: "@gdavis92",
}

const BlogPage = ({ data }) => {
  const {
    allMarkdownRemark: { edges: allPosts },
  } = data
  const posts = allPosts.map(({ node }) => node)
  return (
    <Page id="blog" heading="Writing">
      <Metadata {...{ frontmatter }} />
      <PostFeed showPinned={true} {...{ posts }} />
    </Page>
  )
}

export default BlogPage

export const BlogPageQuery = graphql`
  query BlogPageQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            pin
            title
            date(formatString: "MMM DD, YYYY")
            feat_img
            feat_img_alt
            author
            excerpt
            path
            external_link
          }
          excerpt(pruneLength: 180)
        }
      }
    }
  }
`
